<template>
    <div class="map-page map-page-create-post" :class="{'map-loading': loading }">
        <div class="map-page-content map-center-layout">
            <h1 class="map-page-title">Edit {{ model.name }}</h1>
            <loader />
            <form>
                <label>Content</label>
                <div style="width: 100%; position: relative">
                    <laraberg-editor v-if="!loading" v-model="model.content" />
                </div>
                <checkbox v-model:checked="model.visible_in_case_study">Show on Case Studies</checkbox>
                <footer>
                    <Button
                        class="map-cancel"
                        @click.prevent="$router.push({ name: 'CountriesList' })"
                        >
                        CANCEL
                    </Button>
                    <Button
                        class="map-save"
                        @click.prevent="save"
                        >
                        SAVE
                    </Button>
                </footer>
            </form>
        </div>
    </div>
</template>

<script>
    import Checkbox from '@/components/Inputs/Checkbox';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';
    import LarabergEditor from '@/components/LarabergEditor';

    import { CountryService } from '@/services';

    export default
    {
        name: 'CountriesEdit',
        components:
        {
            Checkbox,
            Button,
            Loader,
            LarabergEditor,
        },
        data()
        {
            return {
                loading: true,
                posts: [],
                model:
                {
                    spotlight_post_id: 0,
                    spotlight_type: '',
                    content: '',
                },
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async created()
        {
            this.loading = true;

            await this.get_country(this.$route.params.id);

            this.loading = false;
        },
        methods:
        {
            async get_country(id)
            {
                const data = await CountryService.get(id);

                this.model = {...this.model, ...data.data}
            },

            async save()
            {
                CountryService.update(this.$route.params.id, this.model).then(() =>
                {
                    this.$toast.success('Country was successfully updated!');
                },
                (error) =>
                {
                    if (error.data.message)
                    {
                        this.$toast.error(error.data.message);
                    }
                    else
                    {
                        this.$toast.error('Something went wrong. Please try again!');
                    }
                });
            },
        }
    }
</script>

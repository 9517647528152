<template>
    <textarea
        id="larabergEditor"
        name="larabergEditor"
        hidden
        :value="value"
        @input="input"
        />
</template>

<script>
export default
{
    name: 'LarabergEditor',
    inheritAttrs: false,
    props:
    {
        modelValue:
        {
            type: String,
            default: ''
        },
        template:
        {
            type: Array,
            default: undefined,
        },
        error:
        {
            type: String,
            default: ''
        }
    },
    emits: ['update:modelValue'],
    data()
    {
        return {
            last_value: '',
        };
    },
    computed:
    {
        value:
        {
            get()
            {
                return this.modelValue;
            },
            set(value)
            {
                this.$emit('update:modelValue', value);
            },
        },
    },
    async mounted()
    {
        const options = this.$laraberg_options();

        // const addEventListener = Window.prototype.addEventListener;

        // Hacky way to disable leave site popup.
        // Window.prototype.addEventListener = function (a, b, c)
        // {
        //     if (a === 'beforeunload')
        //     {
        //         return ;
        //     }
        //
        //     return addEventListener(a, b, c);
        // };

        if (this.template)
        {
            options.template = this.template;
        }

        await this.$laraberg().init('larabergEditor', options);

        // Window.prototype.addEventListener = addEventListener;

        // getEventListeners(window).beforeunload.forEach((e) => e.remove());

        this.sync_content();
    },
    watch:
    {
        value: function(val)
        {
            this.set_content(val);
        }
    },
    methods:
    {
        input(value)
        {
            this.$emit('update:modelValue', value);
        },
        async set_content(value)
        {
            if (value !== this.last_value)
            {
                try
                {
                    await this.$laraberg().setContent(value);
                } catch (e) { console.log(e); }
            }
        },
        sync_content()
        {
            setInterval(() =>
            {
                const value = this.$laraberg().getContent();

                // console.log(value);

                if (value !== this.last_value)
                {
                    this.last_value = value;
                    this.input(this.last_value);
                }

            }, 50);
        }
    }
}
</script>
